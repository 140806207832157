import React, { FunctionComponent } from "react";


const UnseenDot: FunctionComponent = () => {
    return (
        <div className="unseen-dot">
        </div>
    );
};

export default UnseenDot;
